<template>
  <div class="flex items-center justify-between w-full pr-2">
    <div class="py-4 px-2 max-w-[80%]">
      <p class="font-medium truncate">
        {{ props.voucher.campaign.name }}

        <!-- Gift Voucher not activated -->
        <span v-if="props.voucher.vouchers[0]?.giftCredit && !props.voucher.activated" class="text-tertiary-base font-semibold">
          {{ props.voucher.vouchers[0]?.giftCredit?.credit
            ? `${sumVoucherCreditAmount}${currencySymbolCustom(props.voucher.vouchers[0]?.giftCredit?.credit?.currency)}`
            : $t('vouchers.gift_credit') 
          }}
        </span>
      </p>
      <p v-if="props.voucher.expirationTime" class="fs-12">
        {{ getDaysLeft(props.voucher.expirationTime) }} {{ $t('vouchers.daysLeft') }}
      </p>
    </div>

    <!-- Number of vouchers:Enable it when BE is ready -->
    <!-- <div v-if="props.voucher.activated && props.voucher.vouchers.length > 1" class="mr-2 text-tertiary-text font-semibold pb-1">
      {{ $t('vouchers.remaining') }}: {{ props.voucher.vouchers.length }}
    </div> -->

    <!-- Gift Voucher -->
    <div v-if="props.voucher.vouchers[0]?.giftCredit && props.voucher.activated" class="fs-12 text-right">
      {{ $t('vouchers.gift_available') }} 
      <div class="">
        <span class="font-semibold">
          {{ props.voucher.vouchers[0]?.giftCredit?.balance
            ? `${sumVoucherBalanceAmount}${currencySymbolCustom(props.voucher.vouchers[0]?.giftCredit?.balance?.currency)}`
            : $t('vouchers.gift_balance') 
          }}
        </span> 
        {{ $t('vouchers.of') }}
        <span class="text-tertiary-base font-semibold">
          {{ props.voucher.vouchers[0]?.giftCredit?.credit
            ? `${sumVoucherCreditAmount}${currencySymbolCustom(props.voucher.vouchers[0]?.giftCredit?.credit?.currency)}`
            : $t('vouchers.gift_credit') 
          }}
        </span>
      </div>
    </div>
      
    <RPButton
      v-if="!props.voucher.activated"
      data-cy="vouchers-active-btn"
      theme="custom"
      class="mr-0 py-0 px-2 z-10 fs-12 w-20 bg-primary-btn-bg text-primary-btn-text rounded-full h-6" 
      @click="$emit('activate', props.voucher.campaign.id)"
    >
      {{ $t('vouchers.active') }}
    </RPButton>
  </div>
</template>
<script setup lang="ts">
import RPButton from '@/components/RPButton/RPButton.vue';

import { CampaignParticipation, Voucher } from '@/store/vouchers/types';
import { numberKeepPrecision, currencySymbolCustom } from "@/utils/utils";
import { PropType, computed } from 'vue';

const props = defineProps({
  voucher: {
    type: Object as PropType<CampaignParticipation>,
    required: true
  }
});


const emit = defineEmits(['activate']);
const getDaysLeft = (date: string) => {
  const targetDate = new Date(date);
  const today = new Date();

  const timeDiff = targetDate.getTime() - today.getTime();
  const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return Math.abs(daysLeft);
};

const sumVoucherBalanceAmount = computed(() => {
  const sum = props.voucher.vouchers?.reduce(
    (acc: number, voucher: Voucher) => acc + Number(voucher.giftCredit.balance.amount), 0);

  return numberKeepPrecision(sum, 2);
});
const sumVoucherCreditAmount = computed(() => {
  const sum = props.voucher.vouchers?.reduce(
    (acc: number, voucher: Voucher) => acc + Number(voucher.giftCredit.credit.amount), 0);
  return numberKeepPrecision(sum, 2);
});

</script>