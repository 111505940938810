<template>
  <label 
    :for="`select_${fieldType}`" 
    class="block pb-6 text-left cursor-pointer relative w-full"
    :class="[
      $attrs.class,
      {
        'text-error': props.customError && isRequired,
        'text-tertiary-text': !props.customError || !isRequired
      }
    ]"
  >{{ props.label }}
    <input 
      :id="`select_${fieldType}`" 
      :data-cy="`select_${fieldType}`" 
      class="fs-14 font-medium mr-2 border-b border-card-outline w-full cursor-pointer ryd-old"
      :value="props.modelValue === null ? 'Select' : props.modelValue.text"
      :autocomplete="props.autocomplete" 
      disabled
    />
    <span 
      v-if="props.customError && isRequired" 
      class="block py-1 px-0 text-error text-fs-caption"
      data-cy="error-message"
    >
      {{ customError }}
    </span>
    <RPIcon svg-fill="text" type="chevron_left" class="-rotate-90 w-6 absolute top-2 right-0" />
    <button type="button" class="absolute top-0 left-0 h-full w-full border-none box-border" @click="isModalOpen = true" />
  </label>
  <ModalBottom v-if="isModalOpen" @close="isModalOpen = false">
    <p class="fs-16 font-semibold text-left">
      {{ $t(props.options.title) }}
    </p>
    <ul class="pt-6 text-left font-bold">
      <li 
        v-for="item in options.values" 
        :key="item.value" 
        class="mb-6 cursor-pointer" 
        @click.stop.prevent="selectItem(item)"
      >
        {{ item.text }}
      </li>
    </ul>
  </ModalBottom>
</template>

<script setup lang="ts">
import ModalBottom from '@/components/ModalBottom.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import { ref, PropType } from 'vue';

interface Modal {
  title: string,
  values: Values[];

}
interface Values {
  value: string, text: string;
}

const emits = defineEmits(['update:modelValue']);
const props = defineProps({
  fieldType: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  isRequired: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Object as PropType<Values | null>,
    default: null
  },
  customError: {
    type: String,
    default: ''
  },
  autocomplete: {
    type: String,
    default: 'off'
  },
  options: {
    type: Object as PropType<Modal | null>,
    required: true
  }

});

const isModalOpen = ref(false);
const selectItem = (val) => {

  emits('update:modelValue', val);
  isModalOpen.value = false;
};
</script>