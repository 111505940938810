<template>
  <RydTopBar title="Reset password" backBtnVisible @backButtonClick="$router.push('/login')" />

  <div class="view-block px-6 in-car:fs-32 my-8">
    <form ref="forgotPwdForm" @submit.prevent="requestPwd">
      <h2 class="mb-4 in-car:mb-8">
        {{ $t('account.forgot_pwd_title') }}
      </h2>
      <h3 v-if="error" class="mt-5 text-error">
        {{ $t('errors.generic.text') }}
      </h3>

      <label for="loginEmail" 
             class="fs-13 in-car:fs-28 block mb-1 font-bold" 
             :class="{
              'text-error': customErrorEmail,
            }" 
      >{{ t('account.email_label') }}</label>
      <RydInputText 
        id="loginEmail" 
        v-model:valueModel="email" 
        type="email"
        tabindex="1"
        data-cy="loginEmailField"
        @blur="checkField"
        @input="resetErrors" 
      />
      <span 
        v-if="customErrorEmail" 
        class="block py-[5px] px-0 text-error rp-fs-13 in-car:fs-28"
        data-cy="error-message"
      >
        {{ customErrorEmail }}
      </span>
    </form>
    
    <div>
      <RydButton class="mb-8 w-full" @click="requestPwd()">
        {{ $t('account.forgot_pwd_cta_yes') }}
      </RydButton>
      <p />
      <RydButton class="w-full" outline @click="$router.push('/login')">
        {{ $t('account.forgot_pwd_cta_no') }}
      </RydButton>
    </div>
    <RydModalBottom v-if="dialog" @close="dialog = false">
      <div class="py-8">
        <p class="pb-8">
          {{ $t('notifications.new_pwd_request') }}
        </p>
        <RydButton class="w-full mt-6" @click="$router.push('/login')">
          {{ $t('profile.password.success.button_ok') }}
        </RydButton>
      </div>
    </RydModalBottom>
  </div>
</template>
<script setup lang="ts">
import RydModalBottom from '@/components/RydModalBottom/RydModalBottom.vue';
import RydButton from '@/components/RydButton/RydButton.vue';
import RydInputText from '@/components/RydInputText/RydInputText.vue';
import RydTopBar from '@/components/RydTopbar/RydTopBar.vue';


import { checkAndApplyTheme, removeTheme } from '@/utils/utils';
import { useAuthStore } from '@/store/auth/auth';

import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const authStore = useAuthStore();

const email = ref(null);
const error = ref(false);
const dialog = ref(false);
const customErrorEmail = ref(null);


onBeforeUnmount(() => removeTheme());
onMounted(() => checkAndApplyTheme());

const validateEmail = (emailParam: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(emailParam);
};

const checkField = () => {
  if (!validateEmail(email.value)) {
    customErrorEmail.value = t('errors.email_not_valid');
    return false;
  }
  return true;
};

const resetErrors = () => {
  customErrorEmail.value = '';
};

const requestPwd = async () => {
  error.value = false;
  if (!checkField()) return;
  try {
    await authStore.forgotPwd(email.value);
    dialog.value = true;
  } catch (e) {
    error.value = true;
  }
};
</script>