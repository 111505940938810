<template>
  <component
    :is="componentType"
    class="ryd-button f-roboto in-car:fs-32"
    v-bind="{ ...linkAttr, ...isDisabled }"
    :class="[
      `ryd-button--${theme}`,

      {
        'outline': outline,
        'link': link,
      },
    ]"
    @click="onClick"
  >
    <RydIcon v-if="icon" :icon="icon" class="mr-2 h-4 w-4 button-icon" />  
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import RydIcon from '@/components/RydIcon/RydIcon.vue';

const LINK_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external'
};

const attrToLinkTypeMap = {
  [LINK_TYPES.INTERNAL]: { component: 'RouterLink', attr: 'to' },
  [LINK_TYPES.EXTERNAL]: { component: 'a', attr: 'href' }
};

const props = defineProps({
  theme: {
    type: String,
    default: 'primary',
    validator: value => ['primary', 'secondary', 'error', 'custom'].includes(value as string)
  },
  outline: {
    type: Boolean,
    default: false
  },
  link: {
    type: Boolean,
    default: false
  },
  href: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
});
const emits = defineEmits(['click']);
const linkType = computed(() => {
  return props.href?.[0] === '/' ? LINK_TYPES.INTERNAL : LINK_TYPES.EXTERNAL;
});

const componentType = computed(() => {
  const componentType = props.href ? attrToLinkTypeMap[linkType.value].component : 'button';
  return componentType;
});

const isDisabled = computed(() => {
  return props.disabled ? { disabled: true } : {};
});

const linkAttr = computed(() => {
  const attrType = attrToLinkTypeMap[linkType.value].attr;
  return {
    [attrType]: props.href
  };
});

const onClick = () => {
  emits('click');
}

</script>
<style lang="scss">
  .ryd-button {
    @apply flex items-center justify-center 
    text-center text-ryd-lightMain font-bold leading-4 tracking-button 
    h-btn-h in-car:h-20
    w-auto in-car:min-w-60
    px-4 mx-auto 
    rounded-full border-transparent outline-none transition-all duration-500 ease-out cursor-pointer appearance-none 
    relative overflow-hidden;
    -webkit-tap-highlight-color: transparent;

    &:after {
      content: '';
      @apply block absolute opacity-0 top-0 left-0 h-full w-full;
      background: rgba(0, 0, 0, 0.2);
      border-radius: inherit;
      transition: all 0.8s;
    }

    &:active:after {
      opacity: 1;
      transition: 0s;
    }

    &:hover,
    &:focus,
    &:active {
      @apply outline-none;
    }

    &:disabled,
    &[disabled],
    &[disabled='disabled'] {
      @apply opacity-50 cursor-not-allowed pointer-events-none;
    }

    &--primary {
      @apply text-ryd-lightMain bg-ryd-primary;

      &.outline {
        @apply border border-solid border-ryd-primary bg-transparent text-ryd-primary dark:text-ryd-lightMain;
      }

      &.outline .button-icon {
        @apply text-ryd-primary dark:text-ryd-lightMain
      }

      &.link {
        @apply border-none bg-transparent text-ryd-primary dark:text-ryd-lightMain underline;
      }

      & .button-icon {
        @apply text-ryd-lightMain
      }
    }

    &--secondary {
      @apply text-secondary-btn-text bg-ryd-secondary;

      &.outline {
        @apply border border-solid border-ryd-secondary bg-transparent text-ryd-secondary dark:text-ryd-lightMain;

        & .button-icon {
          @apply text-ryd-secondary dark:text-ryd-lightMain
        }
      }

      &.link {
        @apply border-none bg-transparent text-ryd-secondary dark:text-ryd-lightMain underline;
      }

      & .button-icon {
        @apply text-ryd-lightMain
      }
    }

    &--error {
      @apply text-secondary-btn-text bg-ryd-error;

      &.outline {
        @apply border border-solid border-ryd-error bg-transparent text-error;
      }

      &.link {
        @apply border-none bg-transparent text-ryd-error underline;
      }
    }
  }

  .link {
    @apply p-0 h-auto min-w-fit w-auto rounded-none;
  }
</style>
