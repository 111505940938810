<template>
  <div class="flex flex-col relative">
    <label class="fs-13 block mb-1 font-bold" :for="props.id">{{ props.label }}</label>
    <div
      :class="props.disabled
        ? 'pointer-events-none bg-transparent focus:outline-none'
        : 'bg-white cursor-pointer'"
      class="fs-15 block w-full
        p-3
        border border-ryd-gray3 rounded-md 
        focus:border-ryd-gray5 focus:outline-none focus:ring-1 focus:ring-ryd-gray5"
      @click="selectClick"
    >
      <span v-if="props.defaultValue" class="block">
        {{ props.displayValue
          ? (props.defaultValue as RydSelectOption)?.[props.displayValue as string | number]
          : props.defaultValue
        }}
      </span>
      <span v-else class="block text-ryd-gray4">{{ props.placeholder }}</span>
      <RydIcon icon="arrow-dropdown" class=" absolute top-10 right-2" :class="[props.disabled ? '' : 'text-ryd-primary']" />
    </div>
    <input :id="props.id" type="hidden" :name="props.name" :value="props.defaultValue" />
  </div>

  <RydModalBottom v-if="selectModalOpen" 
                  :customClass="['max-h-[430px]']" 
                  @close="selectModalOpen = false"
  >
    <div class="sticky top-0 left-0 w-full pt-10">
      <p class="fs-16 font-semibold text-left">
        {{ props.label }}
      </p>
      <RydDivider class="my-3" />
    </div>
    <div class="scrollbar overflow-hidden mt-8 max-h-[260px]">
      <label
        v-for="(option, index) in options" 
        :key="index"
        :for="`${props.displayValue}-${index}`"
        class="mb-6 flex items-center"
      >
    
        <!-- In case the option is an object we want to display a specific property -->
        <template v-if="props.displayValue && typeof option === 'object'">
          <RydRadio :id="`${props.displayValue}-${index}`" 
                    v-model="selectedOption"
                    name="selectRadio"
                    :value="option"
                    :label="option[props.displayValue as string]"
          />
        </template>
        <template v-else>
          <RydRadio :id="`${props.displayValue}-${index}`"
                    v-model="selectedOption"
                    name="selectRadio"
                    :value="(option as string)"
                    :label="(option as string)"
          />
        </template>
      </label>
    </div>
    <div class="sticky bottom-0 bg-white pb-4">
      <RydDivider class="my-3" />
      <div class="flex justify-between items-center">
        <RydButton outline theme="primary" class="mr-2 w-full min-w-28 max-w-40" @click="selectModalOpen = false">
          {{ $t('car.modal.choice.button.action1') }}
        </RydButton>
        <RydButton theme="primary" class="min-w-28 w-full max-w-40" @click="setOption">
          {{ $t('car.modal.choice.button.action2') }}
        </RydButton>
      </div>
    </div>
  </RydModalBottom>
</template>

<script setup lang="ts">
import RydIcon from '../RydIcon/RydIcon.vue';
import RydModalBottom from '@/components/RydModalBottom/RydModalBottom.vue';
import RydDivider from '../RydDivider.vue';
import RydButton from '../RydButton/RydButton.vue';
import RydRadio from '../RydRadio/RydRadio.vue';

import { ref, PropType } from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: () => '',
  },
  name: {
    type: String,
    default: () => '',
  },
  label: {
    type: [String, Number],
    default: () => '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<RydSelectOption[] | string[] | number[]>,
    default: () => [],
  },
  displayValue: {
    type: [String, Boolean, Number],
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modalScrollHeight: {
    type: String,
    default: () => 'h-full',
  },
  defaultValue: {
    type: [Object, String, Number] as PropType<RydSelectOption | string | number>,
    default: null,
  },
});

type RydSelectOption = Record<PropertyKey, string>;

const emits = defineEmits(['update']);

const selectModalOpen = ref(false);

const selectedOption = ref(props.defaultValue);

const setOption = () => {
  emits('update', selectedOption.value);
  selectModalOpen.value = false;
};

const selectClick = () => {
  selectModalOpen.value = true;
};


</script>
<style lang="scss" scoped>
  .scrollbar {
    scrollbar-color: #265399 white;
    scrollbar-width: thin;
    overflow-y: scroll;

    scroll-snap-type: y mandatory;

    &label {
      scroll-snap-align: start;
    }
  }
</style>