<template>
  <div class="flex items-start flex-col">
    <div v-if="event.information.productType === 'GIFT_CARD'">
      <p class="font-bold fs-16 pt-2">
        {{ event.information?.name }}
      </p>
    </div>
    <div v-else class="border-b border-card-outline py-2 w-full">
      <p class="font-bold fs-16">
        {{ event.information?.poi.brand }}
      </p>
      <div class="fs-12">
        {{ event.information?.poi.address.street }} {{ event.information?.poi.address.houseNumber }}, 
        {{ event.information?.poi.address.zip }} {{ event.information?.poi.address.city }}
      </div>
    </div>
    <div class="flex items-center  justify-between pt-2 w-full">
      <span class="fs-14 font-bold text-tertiary-text">
        {{ formatDate.weekday }} {{ formatDate.date }} - {{ formatDate.time }}
      </span>
      <span class="fs-16 font-bold">
        {{ currencyString(event.information.price.amount, event.information.price.currency) }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">

import { dateString, currencyString } from '@/utils//utils';

import { HistoryPaymentEvent } from '@/store/history/types';
import { PropType, computed } from 'vue';
const props = defineProps({
  event: {
    type: Object as PropType<HistoryPaymentEvent>,
    required: true
  },
});
const formatDate = computed(() => {
  const startDate = new Date(props.event.sampleTime);
  return dateString(startDate);
});
</script>