<template>
  <div class="app__wrapper max-w-appMaxWidth flex flex-col my-0 mx-auto min-h-screen"
       :data-in-car="isLandscape" :class="{ 'in-car': isLandscape }"
  >
    <LoadingOverlay v-if="isLoading" />
    <router-view />
  </div>
  <div id="teleportBeforeFooter" class="w-screen sticky bottom-0 mx-auto" />
</template>

<script setup lang="ts">
import LoadingOverlay from "./components/LoadingOverlay.vue";

import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { useAuthStore } from '@/store/auth/auth';

import { computed } from "vue";
import { useRoute } from "vue-router";

const authState = useAuthStore();
const route = useRoute();

if (import.meta.env.VUE_APP_ENV !== 'dev' && !window.location.href.includes('9000')) {

  const firebaseConfig = {
    apiKey: import.meta.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: "ryd-pay-webview.firebaseapp.com",
    projectId: "ryd-pay-webview",
    storageBucket: "ryd-pay-webview.appspot.com",
    messagingSenderId: "718497430890",
    appId: import.meta.env.VUE_APP_FIREBASE_APP_ID,
  };
  const firebaseApp = initializeApp(firebaseConfig);
  const perf = getPerformance(firebaseApp);
}
const isLoading = computed(() => authState.isLoading);
const isLandscape = computed(() => route.query.inCarDevice === "true");

</script>
<style lang="scss">
  @import url('@/assets/styles/cssVariables.css');

  #app {
    @apply text-fs-body w-full min-h-full relative font-normal bg-primary-background text-primary-text;

    .app__wrapper.in-car {
        max-width: var(--appMaxWidthLandscape);
      }
  }

  // Remove the following css when new UI completely integrated
  .light #app {
    @apply bg-ryd-lightMain text-ryd-darkMain;

    & #ryd-header {
      @apply bg-ryd-lightMain text-ryd-darkMain
    }

  }

  .dark #app {
    @apply bg-ryd-darkMain text-ryd-lightMain;

    & #ryd-header {
      @apply bg-ryd-darkMain text-ryd-lightMain
    }
  }
</style>
