<template>
  <div class="w-full view-block pt-6">
    <div class="px-4">
      <p class="flex justify-between w-full fs-16 py-4">
        <span class="font-medium ">{{ $t('history.gift_card.gift_value') }}</span>
        <span>
          {{
            currencyString(
              deciCentInUnit(Number(props.event.information.item.details.giftCard.credit.amount)),
              props.event.information.item.details.giftCard.credit.currency
            ) }}
        </span>
      </p>
   
      <Divider class="w-full mt-2" />

      <p class="flex justify-between w-full font-medium fs-18 pt-4">
        <span>{{ $t('history.gift_card.amount') }}</span>
        <span>{{ getFormattedAmount }}</span>
      </p>
    </div>
  </div> 


  <div v-if="event.information.giftCard?.redemptionCodes.length" 
       class="text-left w-screen absolute bottom-[50px] left-0 -mb-12 mt-4"
  >
    <div class="text-left w-full bg-primary-darker px-4 py-6">
      <div class="max-w-appMaxWidth flex flex-col items-center mx-auto pb-4">
        <div class="font-bold fs-16 text-left pb-2 w-full flex items-center justify-between">
          <span>
            {{ $t('history.gift_card.image_title') }}
          </span>
          <span class="text-tertiary-text font-normal text-right fs-12">
            <span>
              {{ $t('history.gift_card.expires') }}
            </span>
            {{ dateNoTime(event.information.giftCard.redemptionCodes[0].expirationDate) }}
          </span>
        </div>
        <img :src="props.event.information.item.details?.imageUrl ?? ''" 
             alt="gift card image" 
             class="object-cover w-full h-full max-h-60"
             @error="imageLoadError"
        />
      </div>
      <!-- Code -->
      <div 
        data-cy="gift-code" 
        class="h-11 relative
            border border-card-outline rounded-md 
            text-2xl font-bold 
            flex items-center justify-center
            max-w-appMaxWidth mx-auto
            bg-primary-background"
      >
        {{ event.information.giftCard.redemptionCodes[0].code }}
        <RydToast v-if="codeCopied" 
                  class="absolute top-3 right-2 w-auto"
                  theme="info" 
                  :type="'string'"
                  :duration="8000" 
                  @isHidden="codeCopied = false"
        >
          <p class="text-primary-btn-bg fs-12 font-medium">
            {{ t('gift_card.success.code_copied') }}
          </p>
        </RydToast>
      </div>
    </div>
    <RPButton theme="custom" class="bg-primary-btn-bg text-primary-btn-text rounded-none w-full h-12" @click="copyCode">
      <span>{{ $t('history.gift_card.copy_code') }}</span>
    </RPButton>
  </div>

  <div v-else class="text-center w-full mb-10 flex items-center justify-start">
    <RydIcon icon="symbols/warning" isCustomColor class="w-10 text-white" />
    <span>{{ t('history.error_no_redemption_codes') }}</span>
  </div>
</template>
<script setup lang="ts">
import RPButton from '@/components/RPButton/RPButton.vue';
import RydIcon from '@/components/RydIcon/RydIcon.vue';
import Divider from '@/components/Divider.vue';
import RydToast from '@/components/RydToast/RydToast.vue';
import giftCardFallbackImg from '@/assets/img/gift-card-fallback.png';

import { getFormattedPriceWithCurrencySymbol, dateNoTime, currencyString } from '@/utils/utils';
import { HistoryCostDetail } from '@/store/history/types';

import { computed, PropType, ref, } from 'vue';
import { useI18n } from 'vue-i18n';


const { t } = useI18n();

const props = defineProps({
  event: {
    type: Object as PropType<HistoryCostDetail>,
    required: true
  },
  fromProfile: {
    type: Boolean,
    default: false
  }
});

const deciCentInUnit = (price: number) => Number(price / 1000).toFixed(2);

const getFormattedAmount = computed(() => getFormattedPriceWithCurrencySymbol(
  props.event.information.totalPrice.amount,
  props.event.information.totalPrice.currency,
  2
));

const imageLoadError = (e: Event) => {
  const target = e.target as HTMLImageElement;
  target.src = giftCardFallbackImg;
};

const codeCopied = ref(false);
const copyCode = async () => {
  // For physical mobile device
  // if (typeof window.navigator.share === 'function') {
  //   await window.navigator.share({
  //     text: redemptionCode.value[0].code,
  //   });
  //   return;
  // }

  // For desktop

  await navigator.clipboard.writeText(props.event.information.giftCard.redemptionCodes[0].code);
  codeCopied.value = true;
};
</script>