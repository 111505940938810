<template>
  <TopBar 
    :title="$t('loyalty.q8smiles.title')"
    backBtnVisible
    iconColor="text-white"
    @backButtonClick="backClick"
  />
  <h3 class="fs-20 font-bold self-start my-4 px-4">
    {{ $t('loyalty.q8smiles.create.headline.title') }}
  </h3>
  <p class="px-4 pb-8" v-html="$t('loyalty.q8smiles.add.headline.description', { t_and_c: 'https://q8smiles.be/terms' })" />
  <!-- API error -->
  <div v-if="errorAPI" class="bg-black w-full py-2 px-4 rounded-md mb-3">
    <div class="flex items-center" data-cy="error-message">
      <RPIcon noFill type="error" class="w-5 h-5 mr-2" />
      {{ errorAPI }}
    </div>
  </div>
  <LoadingOverlay v-if="!componentReady" />
  <form v-else-if="!userAPIError && componentReady" class="w-full h-full view-block px-4" @submit.prevent="submitForm">
    <div>
      <!-- Email -->
      <label 
        :for="`loyalty_register_email`" 
        class="block pb-6"
        :class="{
          'text-error': customErrors.email,
          'text-tertiary-text': !customErrors.email
        }"
      >{{ $t('loyalty.q8smiles.form.label.email') }}
        <input 
          :id="`loyalty_register_email`" 
          v-model="vModelForm.email"
          type="text"
          autocomplete="email"
          :data-cy="`loyalty_register_email`" 
          class="fs-14 pt-0 pb-1 font-medium ryd-old"
          @blur="fieldHasError('email', 'Email')"
          @input="resetErrors('email')"
        />
        <span 
          v-if="customErrors.email" 
          class="block py-1 px-0 text-error text-fs-caption"
          data-cy="error-message"
        >
          {{ customErrors.email }}
        </span>
      </label>
      <!-- First name -->
      <label 
        :for="`loyalty_register_email`" 
        class="block pb-6"
        :class="{
          'text-error': customErrors.firstName,
          'text-tertiary-text': !customErrors.firstName
        }"
      >
        {{ $t('loyalty.q8smiles.form.label.firstname') }}
        <input 
          id="loyalty_register_firstName" 
          v-model="vModelForm.firstName"
          type="text"
          autocomplete="given-name"
          data-cy="loyalty_register_firstName" 
          class="fs-14 pt-0 pb-1 font-medium ryd-old"
          @blur="fieldHasError('firstName', 'firstname')"
          @input="resetErrors('firstName')" 
        />
        <span 
          v-if="customErrors.firstName" 
          class="block py-1 px-0 text-error text-fs-caption"
          data-cy="error-message"
        >
          {{ customErrors.firstName }}

        </span>
      </label>
      <!-- Last name -->
      <label 
        :for="`loyalty_register_lastName`" 
        class="block pb-6"
        :class="{
          'text-error': customErrors.lastName,
          'text-tertiary-text': !customErrors.lastName
        }"
      >
        {{ $t('loyalty.q8smiles.form.label.lastname') }}
        <input 
          :id="`loyalty_register_lastName`" 
          v-model="vModelForm.lastName"
          :data-cy="`loyalty_register_lastName`" 
          class="fs-14 pt-0 pb-1 font-medium ryd-old"
          autocomplete="family-name"
          @blur="fieldHasError('lastName', $t('loyalty.q8smiles.form.label.lastname'))"
          @input="resetErrors('lastName')" 
        />
        <span 
          v-if="customErrors.lastName" 
          class="block py-1 px-0 text-error text-fs-caption"
          data-cy="error-message"
        >
          {{ customErrors.lastName }}
        </span>
      </label>
      <div class="flex items-start justify-between w-full">
        <RPSelect
          v-model="vModelForm.gender"
          fieldType="gender_select"
          :label="$t('loyalty.q8smiles.form.label.gender')"
          :options="selectGenderData"
          class="mr-4"
        >
        </RPSelect>
        <!-- Birthday date -->
        <label for="loyalty_register_bDay" class="relative block pb-6 mb-2 text-tertiary-text w-full">
          <p>{{ $t('loyalty.q8smiles.form.label.birthdate') }}</p>
          <input
            id="loyalty_register_bDay"
            :data-cy="`loyalty_register_bDay`"
            placeholder="DD-MM-YYYY" 
            autocomplete="bday"
            type="date"
            :max="maxInputToday"
            pattern="\d{4}-\d{2}-\d{2}"
            class="datepicker-input  cursor-pointer w-full h-full box-border ryd-old"
            @change="dateChange"
          />
          <span 
            v-if="customErrors.bDate" 
            class="block py-1 px-0 text-error text-fs-caption"
            data-cy="error-message"
          >
            {{ customErrors.bDate }}
          </span>
        </label>
      </div>
      <div class="flex items-start justify-between w-full">
        <!-- Lang -->
        <RPSelect
          v-model="vModelForm.lang"
          fieldType="lang_select"
          :label="$t('loyalty.q8smiles.form.label.language')"
          :options="selectLangData"
          isRequired
          class="max-w-[112px] mr-4"
          :customError="customErrors.lang"
          @update:modelValue="resetErrors('lang')"
        >
        </RPSelect>
        <!-- Country -->
        <RPSelect
          v-model="vModelForm.country"
          fieldType="country_select"
          :label="$t('loyalty.q8smiles.form.label.country')"
          isRequired
          :options="selectCountryData"
          :customError="customErrors.country"
          @update:modelValue="resetErrors('country')"
        >
        </RPSelect>
      </div>
    </div>

    <RPButton 
      theme="custom" 
      type="submit" 
      data-cy="address-update" 
      :disabled="Object.values(customErrors).some(item => !!item)"
      class="text-primary-btn-text bg-primary-btn-bg mt-10 mb-6"
    >
      {{ t('account.save') }}
    </RPButton>
  </form>
  <div v-if="userAPIError">
    {{ $t('errors.generic.text') }}
  </div>
</template>
<script setup lang="ts">
import RPButton from '@/components/RPButton/RPButton.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import RPSelect from '@/components/RPSelect/RPSelect.vue';
import TopBar from '@/components/TopBar.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

import { isEmpty, omit } from 'lodash';
import { createOptin } from '@/api/optin';
import { AxiosResponse } from 'axios';

import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth/auth';
import { useLoyaltyStore } from '@/store/loyalty/loyalty';

const route = useRoute();
const router = useRouter();
const authState = useAuthStore();
const loyaltyState = useLoyaltyStore();

const { t } = useI18n();

const componentReady = ref(false);
const userAPIError = ref(false);

onMounted(async () => {
  // Check if we Refreshed the page
  if (!loyaltyState.loyaltyPrograms.length) {
    try {
      userAPIError.value = false;

      await authState.getUserFeatures(route.query.account as string);
      await loyaltyState.loadActiveLoyalty();
    } catch (error) {
      console.log(error);
      userAPIError.value = true;
    } finally {
      componentReady.value = true;
    }
    return;
  }
  componentReady.value = true;

});

const vModelForm: Record<string, any> = reactive({
  email: null,
  firstName: null,
  lastName: null,
  gender: null,
  bDate: null,
  lang: null,
  country: null
});
const customErrors: Record<string, any> = reactive({
  email: null,
  firstName: null,
  lastName: null,
  bDate: null,
  lang: null,
  country: null
});

// Validations
const fieldHasError = (field: string, fieldLabel: string) => {

  if (isEmpty(vModelForm[field])) {

    customErrors[field] = t('errors.required_field_error', { field: fieldLabel });
    return false;
  }
  if (field === 'email' && !validateEmail(vModelForm.email)) {
    customErrors.email = t('errors.email_not_valid');
    return false;
  }
  return true;
};
const validateEmail = (emailParam: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(emailParam);
};
const resetErrors = (field: string) => {
  errorAPI.value = '';
  customErrors[field] = null;
};

const selectGenderData = computed(() => {
  return {
    title: t('loyalty.q8smiles.form.selection.gender.title'),
    values: [
      { value: 'M', text: t('loyalty.q8smiles.form.selection.gender.option1') },
      { value: 'F', text: t('loyalty.q8smiles.form.selection.gender.option2') },
      { value: null, text: t('loyalty.q8smiles.form.selection.gender.option3') },
    ]
  };
});
const selectLangData = computed(() => {
  return {
    title: t('loyalty.q8smiles.form.label.language'),
    values: [
      { value: 'nl', text: 'Netherlands' },
      { value: 'fr', text: 'Français' },
      { value: 'en', text: 'English' },
      { value: 'de', text: 'Deutsch' },
    ]
  };
});

const selectCountryData = computed(() => {
  return {
    title: t('loyalty.q8smiles.form.label.country'),
    values: [
      { value: 'BE', text: 'Belgium' },
      { value: 'LU', text: 'Luxembourg' },
    ]
  };
});

// birth date
const maxInputToday = new Date().toISOString().split("T")[0];
const dateChange = (event: Event | InputEvent) => {
  customErrors.bDate = null;

  vModelForm.bDate = (event.target as HTMLInputElement).value;

  if (isAgeTooLow((event.target as HTMLInputElement).value)) {
    customErrors.bDate = t('loyalty.q8smiles.form.errors.age');
  }
};
const isAgeTooLow = (birthDateString: string) => {

  var today = new Date();
  var birthDate = new Date(birthDateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var monthAge = today.getMonth() - birthDate.getMonth();
  if (monthAge < 0 || (monthAge === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age < 16 ? true : false;
};

// submit
const accountId = computed(() => authState.accountId);
const userId = computed(() => authState.userId);
const selectedProgramToAdd = computed(() =>
  loyaltyState.selectedProgramToAdd || loyaltyState.loyaltyPrograms.find(item => item.provider === 'Q8_SMILES'));

const optinData = computed(() => ({
  name: `${selectedProgramToAdd.value.name}_${selectedProgramToAdd.value.provider}`,
  action: 'set',
  accountId: accountId.value,
  userId: userId.value
}));

const errorAPI = ref('');

const enrollQ8Loyalty = async () => {
  try {
    await createOptin(optinData.value);
    console.log(typeof vModelForm.gender);

    const loyaltyAPIPayload = {
      loyaltyProgramId: selectedProgramToAdd.value._id,
      subscriptionMethod: 'CUSTOMER_ENROLLMENT',
      email: vModelForm.email,
      firstName: vModelForm.firstName,
      lastName: vModelForm.lastName,
      ...(vModelForm.gender?.value && { gender: vModelForm.gender.value }),
      ...(vModelForm.bDate && { birthDate: vModelForm.bDate }),
      language: vModelForm.lang.value,
      countryCode: vModelForm.country.value
    };
    await loyaltyState.registerLoyalty(loyaltyAPIPayload);

    route.query.loyaltyProgramId ? redirectToCheckout() : router.push('/loyalty');
  } catch (e) {
    console.log('loyalty register fail', e);
    if ((e as AxiosResponse).data.message.includes('already enrolled')) {
      errorAPI.value = t('loyalty.q8smiles.form.errors.already_enrolled');
    }
    errorAPI.value = t('loyalty.q8smiles.form.errors.api_error');
    setTimeout(() => {
      errorAPI.value = null;

    }, 5000);
  }
};

const redirectToCheckout = async () => {
  // If coming from checkout, dispatch event
  const event = new CustomEvent("webViewLoyaltyAdd");
  const eventSuccess = new CustomEvent("webViewLoyaltyAddSuccess");

  if (route.query.programId) {
    if (window.webkit) {
      window.webkit.messageHandlers.webViewLoyaltyAdd.postMessage('webViewLoyaltyAdd');
    }
    await window.dispatchEvent(event);

  } else {
    if (window.webkit) {
      window.webkit.messageHandlers.webViewLoyaltyAddSuccess.postMessage('webViewLoyaltyAddSuccess');
    }
    await window.dispatchEvent(eventSuccess);
    router.push('/loyalty');
  }
};

const submitForm = async () => {
  const fieldsToCheck = omit(vModelForm, ['gender', 'bDate']);

  if (Object.values(fieldsToCheck).every(item => !!item)) {
    await enrollQ8Loyalty();
  } else {
    Object.keys(fieldsToCheck).find(item => {
      if (isEmpty(vModelForm[item])) {
        customErrors[item] = t('loyalty.error_field_required');
      }
    });
  }
};

const backClick = () => {
  if (route.query.programId) {
    const urlCallbackObject = new URL('webViewEvent://loyaltyAddBack');
    window.location.replace(urlCallbackObject.toString());
    return;
  }
  router.push('/loyalty/add');
};
</script>

<style lang="scss">

  .datepicker-input {
    text-transform: uppercase;
  }

  .datepicker-input::-webkit-calendar-picker-indicator,
  .datepicker-input::-webkit-inner-spin-button {
    opacity: 0;
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

</style>