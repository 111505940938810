<template>
  <RydTopBar title="Login" />
  <span v-if="errorAPI" class="text-error" data-cy="error-message"> {{ errorAPI }} </span>
  <form ref="loginForm" class="view-block px-4 pb-16" @submit.prevent="login()">
    <div>
      <div class="py-4">
        <label for="loginEmail" 
               class="fs-13 in-car:fs-28 block mb-1 font-bold" 
               :class="{
                'text-error': customErrorEmail,
              }" 
        >{{ t('account.email_label') }}</label>
        <RydInputText 
          id="loginEmail" 
          v-model:valueModel="model.login.email" 
          type="email"
          tabindex="1"
          data-cy="loginEmailField"
          @blur="checkEmailField"
          @input="resetErrors('email')" 
        />
        <span 
          v-if="customErrorEmail" 
          class="block py-[5px] px-0 text-error rp-fs-13 in-car:fs-28"
          data-cy="error-message"
        >
          {{ customErrorEmail }}
        </span>
      </div>
      <div class="relative py-4">
        <label for="loginPassword"
               class="fs-13 in-car:fs-28 block mb-1 font-bold"
               :class="{
                'text-error': customErrorPassword,
              }"
        >
          {{ t('account.password_label') }}
        </label>
        <RydInputText 
          id="loginPassword" 
          v-model:valueModel="model.login.password" 
          :inputType="passwordType"
          class="py-1 read-only:border-b-0"
          autocomplete="on"
          tabindex="2"
          data-cy="loginPasswordField"
          @input="resetErrors('password')"
        />
        <RydIcon 
          :icon="passwordType === 'text' ? 'symbols/pw-show' : 'symbols/pw-hide'"
          isCustomColor
          class="absolute right-1 
            bottom-8 in-car:bottom-8
            in-car:h-10 in-car:w-10 cursor-pointer text-black"
          @click="setPasswordType()"
        />
      </div>
      <span 
        v-if="customErrorPassword" 
        class="block py-[5px] px-0 text-error rp-fs-13 in-car:fs-28"
        data-cy="error-message"
      >
        {{ customErrorPassword }}
      </span>
      <RydButton 
        link 
        href="/forgotten-password"
      >
        {{ $t('account.forgotten_pwd') }}
      </RydButton>
    </div>
    <RydButton 
      type="submit" 
      tabindex="3"
      class="in-car:w-full"
    >
      {{ t('signIn.login.mail_cta', { provider: $t('signIn.providers.mail') }) }}
    </RydButton>
    <!-- <div class="text-center mt-14">
      <GoogleSSO @token="token => loginSSO(token, 'GOOGLE')" />
      <AppleSSO @token="token => loginSSO(token, 'APPLE')" />
      <AmazonSSO actionType="login" />
      <RydButton 
        type="submit" 
        tabindex="3"
      >
        {{ t('signIn.login.mail_cta', { provider: $t('signIn.providers.mail') }) }}
      </RydButton>
    </div> -->
  </form>
  <RydModalBottom v-if="dialogOpen" @close="dialogOpen = false">
    <div class="my-12 in-car:fs-28">
      <h2>
        {{ t('errors.nopsp.title') }}
      </h2>
      <p>
        {{ t('errors.nopsp.text') }} <a href="mailto:hilfe@ryd.one?subject=Problem%20with%20account">hilfe@ryd.one</a>
      </p>
      <RydButton class="mt-6" data-cy="loginNoPSPClose" @click="dialogOpen = false">
        {{ t('errors.nopsp.btn') }}
      </RydButton>
    </div>
  </RydModalBottom>
  <RydModalBottom v-if="userShouldRegister" @close="userShouldRegister = false">
    <div class="my-12 in-car:fs-28">
      <h2>{{ t('signIn.register.dialog.title') }}</h2>
      <p>{{ t('signIn.register.dialog.content') }}</p>
      <RydButton class="mt-6" data-cy="loginNoAccountFoundClose" @click="handleRegisterNavigation">
        {{ t('account.register_cta') }}
      </RydButton>
    </div>
  </RydModalBottom>
</template>
<script setup lang="ts">
import RydButton from '@/components/RydButton/RydButton.vue';
import RydIcon from '@/components/RydIcon/RydIcon.vue';
import RydModalBottom from '@/components/RydModalBottom/RydModalBottom.vue';
import RydInputText from '@/components/RydInputText/RydInputText.vue';
import RydTopBar from '@/components/RydTopbar/RydTopBar.vue';

// import { useLoginRegister } from './composable/useLoginRegister';

import { useAuthStore } from '@/store/auth/auth';
// import { useUiStore } from '@/store/ui/storeUi';
import { checkAndApplyTheme, removeTheme } from '@/utils/utils';

import { omit } from 'lodash';

import { onMounted, reactive, ref, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
// import { encryptOtp } from '@/api/auth/apiAuth';
import { AxiosError } from 'axios';

const authStore = useAuthStore();

const router = useRouter();
const route = useRoute();
const { t } = useI18n();
// const { redirectToCheckout } = useLoginRegister();

const model = reactive({
  login: {
    email: null,
    password: null
  }
});

const errorAPI = ref(null);
const dialogOpen = ref(false);
const userShouldRegister = ref(false);
const loginForm = ref(null);
const customErrorEmail = ref(null);
const customErrorPassword = ref(null);

const passwordType = ref('password');
const setPasswordType = () => {
  passwordType.value = passwordType.value === 'password' ? 'text' : 'password';
};
onBeforeUnmount(() => removeTheme());
onMounted(async () => {
  checkAndApplyTheme();

  const emailWithPlus = route.query.email && (route.query.email as string).replaceAll(' ', '+');
  model.login.email = emailWithPlus || localStorage.getItem('lastUserEmail') || null;

  // if (route.query.encryptedPassword) {
  //   const encryptedPasswordWithPlus = route.query.email && (route.query.encryptedPassword as string).replaceAll(' ', '+');
  //   try {
  //     const decryptedPass = await encryptOtp(emailWithPlus, encryptedPasswordWithPlus);
  //     model.login.password = decryptedPass;
  //     login();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
});

const checkEmailField = () => {
  if (!model.login.email?.length) {
    console.log('here');

    customErrorEmail.value = t('errors.required_field_error', { field: 'Email' });
    return false;
  }
  if (!validateEmail(model.login.email)) {
    customErrorEmail.value = t('errors.email_not_valid');
    return false;
  }
  return true;
};

const validateEmail = (emailParam: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(emailParam);
};

const checkFieldsOnSubmit = () => {
  checkEmailField();

  if (!model.login.password) {
    customErrorPassword.value = t('errors.required_field_error', { field: 'Password' });
    return false;
  }
  return true;
};

const login = async () => {
  if (!checkFieldsOnSubmit()) return;
  try {
    await authStore.login(model.login.email, model.login.password);
    const loginEvent = new CustomEvent("webViewLoginToken", { detail: { authToken: authStore.authToken } });

    window.dispatchEvent(loginEvent);
    if (window.webkit) {
      window.webkit.messageHandlers.webViewCarNewPreferred.postMessage(authStore.authToken);
    }
  } catch (e) {
    checkErrorMessage(e as AxiosError);
  }
};
const checkErrorMessage = (errorParam: AxiosError) => {
  const errorMap = {
    'wrong_login_details': () => t('errors.wrong_login_details'),
    'temp_pass': () => router.push({ path: '/change-tmp-password', query: { ...omit(route.query, 'encryptedPassword') } }),
    'noPSP': () => dialogOpen.value = true,
    default: () => errorAPI.value = t('errors.generic.text')
  };
  const getError = errorMap[errorParam?.message as keyof typeof errorMap] || errorMap.default;
  getError();
};

// const loginSSO = async (token: string, provider: string) => {
//   try {
//     await authStore.loginOpenId(token, provider);

//     trackSSOLogin(provider);

//     redirect();
//   } catch (e) {
//     if ((e as AxiosError).message === 'not_registered') {
//       userShouldRegister.value = true;
//       return;
//     }
//   }
// };


const handleRegisterNavigation = () => {
  userShouldRegister.value = false;
  // router.replace('/register');
};


const resetErrors = (field: string) => {
  if (field === 'email') {
    customErrorEmail.value = '';
  } else if (field === 'password') {
    customErrorPassword.value = '';
  }
};
</script>