<template>
  <input :id="props.id" v-model="model" type="radio" :value="props.value" :name="props.name" />
  <span class="fs-13 pl-2">{{ props.label }}</span>
</template>
<script setup lang="ts">
const props = defineProps({
  label: {
    type: [String, Number],
    default: ''
  },
  name: {
    type: String,
    required: true
  },
  value: {
    type: [String, Number, Object],
    required: true
  },
  id: {
    type: String,
    default: () => '',
  }
});

const model = defineModel();

</script>

<style lang="scss" scoped>
  input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #265399;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
</style>