<template>
  <TopBar 
    :title="$t('history.details.title_cost')"
    backBtnVisible
    isCustomColor
    iconColor="text-white"
    @backButtonClick="$router.push('/history')"
  />
  <LoadingOverlay v-if="isLoading" />
  <div v-if="!categoryInit" class="px-6 pt-4">
    <p class="font-medium text-left text-tertiary-text">
      {{ $t('history.details.cost.filter_category_title') }}
    </p>
    <ul class="pt-6 text-left font-bold">
      <template v-for="category in Object.keys(COST_EVENTS_TEXT_MAP)">
        <li 
          v-if="category !== 'GIFT_CARD'" 
          :key="category" 
          class="mb-6" 
          
          @click="categoryInit = category"
        >
          <RPIcon :type="`history/${getCategoryIconUtils(category)}`" noFill class="mr-3" />
          {{ $t(`history.details.cost.category.${getCategoryTextUtils(category)}`) }}
        </li>
      </template>
    </ul>
  </div>
  <CostDetails 
    v-else
    :category="categoryInit"
    isCreateComponent
    :error="APIError"
    @create="createEvent"
  />
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import CostDetails from './components/CostDetails.vue';

import { HistoryCreateCostPayload } from '@/store/history/types';
import { useHistoryStore } from '@/store/history/history';
import {
  getCategoryIconUtils,
  getCategoryTextUtils,
  COST_EVENTS_TEXT_MAP
} from '@/store/history/historyUtils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

const categoryInit = ref(null);
const historyState = useHistoryStore();
const router = useRouter();

const isLoading = ref(false);
interface PayloadCreate {
  form: HistoryCreateCostPayload;
  images: {
    imageBuffer: ArrayBuffer;
    type: string;
  }[];
}
const APIError = ref();
const createEvent = async (payload: PayloadCreate) => {
  try {
    isLoading.value = true;
    APIError.value = null;

    await historyState.createEventCost(payload.form, payload.images);
    router.push('/history');
  } catch (error: any) {
    console.log(error);
    if (error.response?.data?.message?.includes('carOdometerM')) {
      APIError.value = 'mileage';
    } else {
      router.push('/error');
    }
  } finally {
    isLoading.value = false;
  }
};
</script>