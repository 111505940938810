<template>
  <TopBar 
    backBtnVisible 
    :title="$t('profile.wash.title')" 
    data-cy="back-to-profile" 
    iconColor="text-white"
    @backButtonClick="redirectToApp()"
  />

  <template v-if="componentReady">
    <div v-if="eventToShow?.length" ref="historyRef" class="w-full px-4 pb-10 pt-6">
      <div v-for="(event, index) in eventToShow" :key="event.id">
        <RPCard
          v-if="event.information.redemptionCodes?.length"
          hasClick
          :data-cy="`history_list_${index}`"
          class="mb-4"
          @click="$router.push(`/profile/wash/${event.id}`)"
        >
          <div class="flex items-center w-full pt-2 px-4 pb-2">
            <div class="w-11  flex flex-col items-center pr-3 pt-1">
              <RPIcon :type="`history/wash`" noFill />
            </div>
            <div class="pl-3 flex flex-col w-full border-l border-card-outline">
              <div class="border-b pb-2 w-full border-card-outline">
                <p class="font-bold fs-14">
                  {{ event.information?.marketingName || $t(`history.details.cost.category.wash`) }}
                </p>
              </div>
              <div class="flex items-center  justify-between pt-2 w-full">
                <div class="text-tertiary-text font-normal fs-12 text-left">
                  <span v-if="event.information?.redemptionCodes[0].isRedeemed">
                    {{ $t('history.wash.used') }} {{ dateNoTime(event.information?.redemptionCodes[0]?.updatedAt) }}
                  </span>
                  <span v-else>
                    {{ $t('topup.success.wash.expires') }} {{ dateNoTime(event.information?.redemptionCodes[0]?.expirationDate) }}
                  </span>
                </div>
                <span class="fs-16 font-bold">
                  {{ currencyString(event.information?.totalPrice.amount, event.information?.totalPrice.currency) }}
                </span>
              </div>
            </div>
          </div>
        </RPCard>
      </div>
      <LoadingSpinner v-if="loadMore" />
    </div>
    <span v-else class="pt-[15px] text-center px-4">
      {{ $t('history.wash.empty') }}
    </span>
  </template>
  <LoadingOverlay v-else />
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import RPCard from '@/components/RPCard/RPCard.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';

import { currencyString, dateNoTime } from '@/utils/utils';
import { HistoryCostEvent } from '@/store/history/types';

import { useHistoryStore } from '@/store/history/history';
import { computed, onMounted, ref, onBeforeMount } from 'vue';
import { debounce } from 'lodash';

const historyState = useHistoryStore();

const componentReady = ref(false);
const historyRef = ref<HTMLElement | null>(null);

onBeforeMount(async () => {
  // no needs to reload the wash tickets
  if (!!historyState.washEvents?.length) {

    componentReady.value = true;
    return;
  }
  try {
    historyState.washEventsPage = 1;

    !historyState.washEvents?.length && await historyState.getHistoryEventsWash(1);
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
});


// infinite scroll
const heightToRemoveFromScroll = 44; // 44 header
const loadMore = ref(false);
const endOfEvents = ref(false);

onMounted(() => {
  window.addEventListener('scroll', debounce(() => {
    if (historyRef.value && !endOfEvents.value) {
      checkScroll();
    }
  }, 1000));

});
const checkScroll = async () => {
  const scrollPos = document.documentElement.scrollTop - heightToRemoveFromScroll;
  const docHeight = historyRef.value!.offsetHeight - window.innerHeight;
  const tolerance = 10;

  const bottomOfWindow = (scrollPos + tolerance) >= docHeight;

  if (bottomOfWindow && allEvents.value.length >= 20) {
    loadMore.value = true;
    historyState.washEventsPage++;
    try {
      const newEvents = await historyState.getHistoryEventsWash(historyState.washEventsPage);
      window.scrollTo(0, docHeight);
      if (!newEvents.length) { endOfEvents.value = true; }

    } catch (error) {
      console.log(error);
    } finally {
      loadMore.value = false;
    }
  }
};
//Events to show in list
const allEvents = computed<HistoryCostEvent[]>(() => historyState.washEvents);
const eventToShow = computed(() => allEvents.value?.filter(event =>
  event.triggerEventTypeAtSource === 'ORDER'
));
const redirectToApp = () => {
  const urlCallbackObject = new URL('webViewEvent://profileWashBack');
  window.location.replace(urlCallbackObject.toString());
};

</script>
