<template>
  <div class="px-4 view-block cost-details">
    <div>
      <div class="flex w-full justify-between">
        <!-- Category -->
        <div class="mt-4 flex flex-col items-center">
          <label class="text-tertiary-text fs-12 font-medium">
            {{ $t('history.details.cost.label_category') }}
          </label>
          <button 
            class="text-left mt-2  w-14 flex justify-center rounded-md items-center h-10"
            :class="{ 'border border-card-outline': isCreateComponent }"
            :disabled="!isCreateComponent"
            @click.prevent="isCreateComponent ? modalType = 'category' : null"
          >
            <RPIcon v-if="categoryIcon" :type="`history/${categoryIcon}`" noFill />
          </button>
        </div>
        <!-- Cost -->
        <label class="text-tertiary-text mt-4 block w-full ml-4 fs-12 font-medium" for="priceInCents">
          {{ $t('history.details.cost.label_cost') }}
          <div class="relative">
            <input
              id="priceInCents"
              v-model.number="vModelForm.priceInCents"
              type="text"
              pattern="[0-9]*"
              min="0"
              inputmode="numeric"
              class="cost-details__formatted-cost ryd-old
              opacity-0 w-1 pointer-events-none
              absolute bottom-0 right-0"
              @input="onlyNumbers($event, 'priceInCents')"
              @keydown="inputKeyDown"
              @focusout="validatePriceInCents"
            />
            <div
              class="cost-details__formatted-cost
              flex items-center justify-end
              border border-card-outline border-solid rounded-md
              mt-2 pr-2 h-10
              text-right fs-18 text-white font-medium"
            >
              <span class="text-tertiary-text font-medium mr-1">{{ getCurrencySymbol }}</span> 
              {{ formattedCost }}
            </div>
          </div>
          <p v-if="errorMessage.priceInCents" class="text-error">
            {{ $t(errorMessage.priceInCents) }}
          </p>
        </label>
      </div>
      <div class="mt-4 w-full flex">
        <div class="mr-2 border-b border-card-outline font-medium" :class="isGeneralLayout ? 'w-3/4' : 'w-full'">
          <label class="text-tertiary-text fs-12">
            {{ $t('history.details.cost.label_date_time') }}
          </label>
          <div class="">
            <!-- DateTime -->
            <label for="dateTime" class="relative flex items-center mb-2">
              <RPIcon type="history/clock" noFill class="mr-4" />
              <p>{{ formatDate.weekday }} {{ formatDate.date }} - {{ formatDate.time }}</p>
              <input
                id="dateTime"
                v-model="vModelForm.dateTimeUtility" 
                type="datetime-local"
                step="any"
                class="ryd-old datepicker-input absolute top-0 left-0 cursor-pointer opacity-0 w-full h-full box-border"
                @change="dateChange"
              />
              <input v-model="vModelForm.dateTime" type="hidden" />
            </label>
          </div>
        </div>
        <!-- Repeat -->
        <button
          v-if="isGeneralLayout"
          class="border-b border-card-outline flex flex-col w-1/4 ml-4 justify-between font-medium"
          @click.prevent="modalType = 'repeat'"
        >
          <label class="text-tertiary-text fs-12">
            {{ $t('history.details.cost.label_repeat') }}
          </label>
          <input v-model="vModelForm.recurrencePeriod" type="hidden" />
          <p class="mb-2.5">
            {{ $t(`history.details.cost.repeat.${recurrenceText}`) }}
          </p>
        </button>
      </div>
      <div v-if="categoryTopUp.includes(vModelForm.category)" class="mt-4 w-full flex items-start">
        <!-- Quantity -->
        <label for="quantityMilliLiters" class="text-tertiary-text mr-2 w-full fs-12 font-medium relative">
          {{ $t('history.details.cost.label_quantity') }}
          <input
            id="quantityMilliLiters"
            v-model.number="vModelForm.quantityMilliLiters" 
            type="text"
            pattern="[0-9]*"
            min="0"
            inputmode="numeric"
            autocomplete="off"
            class="ryd-old cost-details__formatted-quantity
              opacity-0 w-1
              absolute bottom-0 left-0"
            @input="onlyNumbers($event, 'quantityMilliLiters')"
            @keydown="inputKeyDown"
            @focusout="validateQuantity()"
          />
          <div class="cost-details__formatted-quantity
            flex items-center justify-start
            border-b border-card-outline border-solid
            pt-2 pr-2 pb-2.5 text-white"
          >{{ displayQuantity }} {{ vModelForm.category === 'FUEL_TOP_UP' ? 'l' : 'kW' }}</div>
          <p v-if="errorMessage.quantity" class="text-error">
            {{ $t(errorMessage.quantity) }}
          </p>
        </label>
        <!-- Mileage -->
        <label class="text-tertiary-text relative w-full fs-12 font-medium" for="mileage">
          {{ $t('history.details.cost.label_mileage') }}
          <input
            id="mileage"
            v-model.number="vModelForm.mileage"
            type="text"
            pattern="[0-9]*"
            min="0"
            autocomplete="off"
            inputmode="numeric"
            class="cost-details__formatted-mileage ryd-old
              opacity-0 w-1
              absolute bottom-0 left-0"
            @input="onlyNumbers($event, 'mileage')"
            @keydown="inputKeyDown"
          />
          <div class="cost-details__formatted-mileage
            flex items-center justify-start
            border-b border-card-outline border-solid
            pt-2 pr-2 pb-2.5 text-white"
          >{{ displayMileage }} km</div>
          <div v-if="errorMessage.mileage && isErrorMileage" class="text-error mt-2">
            {{ $t(errorMessage.mileage) }}
          </div>
        </label>
      </div>
      
      <!-- Title -->
      <label v-if="isGeneralLayout" class="text-tertiary-text block mt-4 fs-12 font-medium">
        {{ $t('history.details.cost.label_title') }}
        <input v-model="vModelForm.name" type="text" class="ryd-old" />
      </label>
      <!-- Comments -->
      <label
        v-if="isGeneralLayout || vModelForm.category === 'PARKING'"
        class="text-tertiary-text mt-4 block fs-12 font-medium"
      >
        {{ $t('history.details.cost.label_comment') }}
        <input v-model="vModelForm.notes" type="text" class="ryd-old" />
      </label>
      <!-- Tank -->
      <RPCheckbox
        v-if="categoryTopUp.includes(vModelForm.category)" 
        id="tankLevel" 
        v-model="vModelForm.tankLevel"
        :label="vModelForm.category === 'FUEL_TOP_UP' ? $t('history.details.cost.label_tank_level') : $t('history.details.cost.label_ev_level')"
        class="mt-4"
      />
      <!-- image -->
      <template v-if="!categoryTopUp.includes(vModelForm.category)">
        <div v-if="vModelForm.images.length" class="mt-8">
          <img 
            v-for="image in vModelForm.images" 
            :key="image.id" 
            :src="image.thumbnailUrl" 
            class="w-full h-20 my-4 object-cover border-2 border-card-outline rounded-md box-border"
            @click="fullScreenImage = image.imageUrl"
          />
        </div>
        <template v-if="uploadedImageToDisplay.length">
          <div v-for="(image, index) in uploadedImageToDisplay" :key="image" class="relative">
            <RPIcon
              type="close"
              svgFill="text"
              class="w-8 absolute -top-2 -right-2 bg-error rounded-full"
              @click="removeNotUploadImage(index + 1)"
            />
            <img
              :src="image"
              class="w-full h-20 my-4 object-cover border-2 border-card-outline rounded-md box-border"
              @click="fullScreenImage = image"
            />
          </div>
        </template>
      
        <label v-if="totalImages < 4" class="relative mt-6 w-full flex justify-center" for="addFile">
          <RPIcon type="history/add-image" noFill />
          <input
            id="addFile"
            class="ryd-old file-picker absolute top-0 left-0 cursor-pointer opacity-0 w-full h-full box-border"
            type="file"
            accept="image/jpg,image/jpeg,image/png" 
            @change="imageChange"
            @click="errorMessage.imageSize = null"
          >
        </label>
        <p v-if="errorMessage.imageSize" class="text-error text-center">
          {{ $t(errorMessage.imageSize) }}
        </p>
        <template v-if="fullScreenImage">
          <div class="w-screen h-screen absolute top-0 left-0 bg-black/50 z-20 flex items-center justify-center">
            <div class="absolute top-0 left-0 w-full bg-black/70 h-11 text-right">
              <RPIcon type="close" svgFill="text" class="w-11" @click="fullScreenImage = null" />
            </div>
            <img :src="fullScreenImage" class="w-full" />
          </div>
        </template>
      </template>
    </div>
    <div v-if="!historyState.thingToShowEvents?.isArchived">
      <RPButton
        v-if="isCreateComponent"
        class="mb-6 mt-4"
        @click="validateForm('create', { form: vModelForm, images: uploadedImage })"
      >
        {{ $t('history.details.cost.add_cta') }}
      </RPButton>
      <template v-else>
        <RPButton
          class="mt-4"
          @click="validateForm('update', { form: vModelForm, images: uploadedImage })"
        >
          {{ $t('history.details.cost.save_cta') }}
        </RPButton>
        <RPButton
          theme="error"
          class="mt-2 mb-6"
          @click="modalType = props.event.information.recurrence ? 'delete' : 'deleteOnce'"
        >
          {{ $t('history.details.cost.remove_cta') }}
        </RPButton>
      </template>
    </div>
  </div>
  <CostDetailsModals 
    v-if="modalType" 
    :modalIs="modalType" 
    :selectedCategory="vModelForm.category" 
    :selectedRecurrence="vModelForm.recurrencePeriod"
    @close="modalType = null"
    @categoryChange="categoryChange"
    @recurrenceChange="recurrenceChange"
    @typeOfDelete="deleteEvent"
  />
</template>
<script setup lang="ts">
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import RPCheckbox from '@/components/RPCheckbox.vue';
import CostDetailsModals from './CostDetailsModals.vue';

import { getCategoryIconUtils, RECURRENCE_TEXT_MAP } from '@/store/history/historyUtils';
import { useAuthStore } from '@/store/auth/auth';
import { useHistoryStore } from '@/store/history/history';

import { HistoryCostDetail, HistoryCreateCostPayload } from '@/store/history/types';
import { computed, PropType, ref, reactive, onMounted, watch } from 'vue';
import {
  dateString,
  convertUTCDateToLocalDate,
  CURRENCY_DECICENT_MAP,
  currencySymbol,
  localeNumber,
  localeNumberWithFractionDigits
} from '@/utils/utils';
import { isEmpty } from 'lodash';
import Compressor from 'compressorjs';

const authStore = useAuthStore();
const historyState = useHistoryStore();
const props = defineProps({
  event: {
    type: Object as PropType<HistoryCostDetail>,
    default: () => ({})
  },
  category: {
    type: String,
    default: null
  },
  isCreateComponent: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  }
});
const emit = defineEmits(['create', 'update', 'delete']);
const dateTimeFormat = (date: string) => convertUTCDateToLocalDate(date);

const vModelForm: Record<string, any> = reactive<HistoryCreateCostPayload>({
  category: props.category,
  priceInCents: 0,
  dateTimeUtility: dateTimeFormat(new Date().toISOString()),
  dateTime: new Date().toISOString(),
  recurrencePeriod: 'NO',
  quantityMilliLiters: 0,
  mileage: 0,
  tankLevel: false,
  name: null,
  notes: null,
  images: [],
  currency: Object.keys(CURRENCY_DECICENT_MAP)
    .find(val =>
      CURRENCY_DECICENT_MAP[val as keyof typeof CURRENCY_DECICENT_MAP] === authStore.userCurrency) ?? "euroDeciCent"
});

// Errors ----------
const errorMessage = reactive({
  priceInCents: null,
  quantity: null,
  mileage: null,
  imageSize: null
});
const isErrorMileage = computed(() => props.error === 'mileage');
// Watch for the create event
watch(isErrorMileage, (newVal) => {
  if (newVal) { errorMessage.mileage = 'history.details.cost.error_mileage'; }
});

onMounted(() => {
  if (isErrorMileage.value) { errorMessage.mileage = 'history.details.cost.error_mileage'; }

  if (props.category) { vModelForm.category = props.category; }
  if (!isEmpty(props.event)) {
    const info = props.event.information;
    vModelForm.category = info?.category;
    vModelForm.priceInCents = formatAPIPrice.value;
    vModelForm.dateTimeUtility = dateTimeFormat(props.event.sampleTime);
    vModelForm.dateTime = props.event.sampleTime;
    vModelForm.recurrencePeriod = info?.recurrence?.period ?? 'NO';
    vModelForm.quantityMilliLiters = Number((info?.item.details.quantity * 100).toFixed(0)) ?? 0;
    vModelForm.mileage = mileageInKM.value ?? 0;
    vModelForm.tankLevel = info?.tankIsFull;
    vModelForm.name = info?.item.details.name;
    vModelForm.notes = info?.notes;
    vModelForm.images = info?.images;
    vModelForm.currency = Object.keys(CURRENCY_DECICENT_MAP)
      .find(val =>
        CURRENCY_DECICENT_MAP[val as keyof typeof CURRENCY_DECICENT_MAP] === info?.totalPrice.currency) ?? "euroDeciCent";
  }
});

const categoryTopUp = ['EV_CHARGE', 'FUEL_TOP_UP'];

// form validations ----------

// Avoid to move the cursor in the invisible input
const inputKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault();
  }
  // we reset the error form the API, here
  errorMessage.mileage = null;
};
/**
 * @desc Avoid any chars other than numbers
  * we convert the number coming from input to string to be able to apply the replace()
  * and re-convert it in string again
  *
  * Some Android soft keyboards don't have KEYPRESS event
  * Same as the event.key, some keys return "Unidentified"
  * So we have to rely only on the input change event
  * @see https://developer.android.com/training/keyboard-input/commands.html
  * 
*/
const onlyNumbers = (event: InputEvent | Event, ref?: string) => {
  // Having the input width 1px can cause that the cursor is not at the end
  // we are forcing the cursor to be at the end
  const inputElement: HTMLElement = document.getElementById(ref);
  inputElement.focus();
  (inputElement as HTMLInputElement).setSelectionRange(
    (inputElement as HTMLInputElement).value.length, (inputElement as HTMLInputElement).value.length
  );

  errorMessage[ref as keyof typeof errorMessage] = null;
  // errorMessageAPI.value = null;
  const inputTypedValue = (event as InputEvent).data;

  if (inputTypedValue === null) return;

  const refString = String(vModelForm[ref]);

  if (Number(vModelForm.mileage) > 1000000) {
    const lessThenOneMillion = refString.slice(0, -1);

    vModelForm.mileage = Number(lessThenOneMillion);
  }


  if (!/[0-9]/.test(inputTypedValue)) {
    const deleteLetters = refString.replace(inputTypedValue, '');

    (event.target as HTMLInputElement).value = deleteLetters;
    vModelForm[ref] = Number(deleteLetters);
  }
};

const validatePriceInCents = async () => {
  errorMessage.priceInCents = null;
  if (!vModelForm.priceInCents) {
    errorMessage.priceInCents = 'history.details.cost.error_required';
    return false;
  }
};
const validateQuantity = async () => {
  errorMessage.quantity = null;
  if (!vModelForm.quantityMilliLiters) {
    errorMessage.quantity = 'history.details.cost.error_required';
    return false;
  }
};
const validateMileage = async () => {
  errorMessage.mileage = null;
  if (!vModelForm.mileage) {
    errorMessage.mileage = 'history.details.cost.error_required';
    return false;
  }
};
// date time picker -----------
const formatDate = computed(() => {
  const startDate = new Date(vModelForm.dateTime);
  return dateString(startDate);
});

// Element to show based on the category
const GENERAL_LAYOUT_CATEGORY_MAP =
  ['CAR_CARE', 'CAR_PARTS', 'CAR_WASH', 'TAX', 'INSURANCE', 'LEASING', 'MISC', 'REPAIR'];
const isGeneralLayout = computed(() => GENERAL_LAYOUT_CATEGORY_MAP.includes(vModelForm.category));

// cost input ----------
const formatAPIPrice = computed(() => {
  const num = parseFloat(props.event?.information?.totalPrice.amount); // convert string to number
  return Number((num * 100).toFixed(2));
});
const formattedCost = computed(() => localeNumberWithFractionDigits(vModelForm.priceInCents / 100));
const getCurrencySymbol = computed(() => currencySymbol(vModelForm.currency));

// Mileage ----------
const mileageInKM = computed(() => {
  // happens that the carOdometerM is not present at all
  if (!props.event?.information?.carOdometerM) return 0;

  const fromMToKM = props.event?.information?.carOdometerM / 1000;
  return Number(fromMToKM.toFixed(0));
});
const displayMileage = computed(() => vModelForm.mileage ? localeNumber(vModelForm.mileage) : 0);

// Quantity ----------
const displayQuantity = computed(() => localeNumberWithFractionDigits(vModelForm.quantityMilliLiters / 100));

// Modals ----------
const modalType = ref(null);
const categoryChange = (category: string) => {
  vModelForm.category = category;
  modalType.value = null;
};
const recurrenceChange = (recurrence: string) => {
  vModelForm.recurrencePeriod = recurrence;
  modalType.value = null;
};
const deleteEvent = (deleteType: string) => {
  emit('delete', { category: vModelForm.category, deleteType });
  modalType.value = null;
};
// category get icon ----------
const categoryIcon = computed(() =>
  getCategoryIconUtils(vModelForm.category));

// recurrence get text ----------
const recurrenceText = computed(() => {
  return RECURRENCE_TEXT_MAP[vModelForm.recurrencePeriod as keyof typeof RECURRENCE_TEXT_MAP];
});

const dateChange = (event: Event | InputEvent) => {
  vModelForm.dateTime = new Date((event.target as HTMLInputElement).value).toISOString();
};

// Images ----------
const fullScreenImage = ref(null);

const totalImages = computed(() => uploadedImageToDisplay.value.length + vModelForm.images.length);
const uploadedImage = ref([]);
const uploadedImageToDisplay = ref([]);
const removeNotUploadImage = (index: number) => {
  uploadedImageToDisplay.value.splice(index - 1, 1);
};
const imageChange = (e: Event | InputEvent) => {
  const selectedImages = (<HTMLInputElement>e.target).files[0];
  if ((<HTMLInputElement>e.target).files[0].size > 6000000) {
    errorMessage.imageSize = 'history.error_imageSize';
  } else {
    new Compressor(selectedImages, {
      quality: 0.6,
      maxWidth: 1000,
      convertSize: 900000,
      success(result) {
        uploadedImageToDisplay.value.push(URL.createObjectURL(result));
        const reader = new FileReader();

        reader.readAsArrayBuffer(result);
        reader.onload = function () {
          uploadedImage.value.push({ imageBuffer: reader.result, type: (<HTMLInputElement>e.target).files[0].type });
        };
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
};

const validateForm = async (emitAction: "create" | "update" | "delete", payload: unknown) => {
  errorMessage.quantity = null;
  if (categoryTopUp.includes(vModelForm.category)) {
    await Promise.all([validateQuantity(), validateMileage()]);
  }
  await validatePriceInCents();
  if (Object.values(errorMessage).some(attr => attr !== null)) return;
  emit(emitAction, payload);
}

</script>

<style lang="scss">
  .cost-details {

    .datepicker-input::-webkit-calendar-picker-indicator,
    .file-picker::-webkit-file-upload-button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    &__formatted-cost:first-of-type:focus+&__formatted-cost,
    &__formatted-mileage:first-of-type:focus+&__formatted-mileage,
    &__formatted-quantity:first-of-type:focus+&__formatted-quantity {
      @apply border-white
    }

  }
</style>