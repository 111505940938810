<template>
  <div class="flex flex-col">
    <label class="fs-13 block mb-1 font-bold" :for="props.id">{{ props.label }}</label>
    <input
      :id="props.id"
      v-model="valueModel"
      :name="props.name"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      class="
      fs-16-ls block w-full
      p-3
      border border-ryd-gray3 rounded-s
      focus:border-ryd-gray5 focus:outline-none focus:ring-1 focus:ring-ryd-gray5"
      :class="{ 'bg-transparent': props.disabled }"
      type="number"
    />
  </div>
</template>

<script setup lang="ts">

const props = defineProps({
  id: {
    type: String,
    default: () => '',
  },
  name: {
    type: String,
    default: () => '',
  },
  label: {
    type: [String, Number],
    default: () => '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  }
});

const valueModel = defineModel('valueModel');
</script>