<template>
  <div class="view-block px-4">
    <form class="view-block" @submit.prevent="sendSubscriptionNumber">
      <div class="flex-1 w-full">
        <h3 class="text-left fs-20 my-4 font-bold">
          {{ $t('loyalty.add_card') }}
        </h3>
        <small
          class="block mb-6"
          v-html="$t('loyalty.agb_text', { program: program.name, main_agb: program.tncs })"
        />
        <!-- API error -->

        <div v-if="errorAPI" class="bg-black w-full py-2 px-4 rounded-md mb-3">
          <div class="flex items-center" data-cy="error-message">
            <RPIcon noFill type="error" class="w-5 h-5 mr-2" />
            {{ errorAPI }}
          </div>
        </div>
        <div v-if="isQ8" class="pb-4 w-full">
          <label for="email_loyalty" class="text-tertiary-text font-medium">
            {{ $t('loyalty.q8smiles.form.label.email') }}

            <input
              id="email_loyalty"
              v-model="modelEmail"
              class="ryd-old"
              type="email"
              data-cy="loyalty_email"
              @input="resetError"
              @focusout="validateEmail" 
            />
          </label>
          <p v-if="errorEmail" class="text-error">
            {{ $t(errorEmail) }}
          </p>
        </div>
        <div class="flex items-start w-full">
          <LoyaltyIcon :programName="program.name" class="h-12 block" />
          <div class="w-full">
            <label for="card_number" class="text-tertiary-text font-medium">
              {{ isQ8 ? $t('loyalty.q8smiles.form.label.cardnumber') : program.name }}
              <input
                id="card_number"
                v-model="cardNumber"
                class="ryd-old"
                type="text"
                data-cy="loyalty_card_number"
                @input="resetError"
                @focusout="isCardValid"
              />
            </label>
            <p v-if="errorCard" class="text-error">
              {{ $t(errorCard) }}
            </p>
          </div>
        </div>
      </div>
      <RPButton
        theme="custom"
        class="text-primary-btn-text
        bg-primary-btn-bg mb-6"
        type="submit"
      >
        {{ $t('loyalty.save_cta') }}
      </RPButton>
    </form>
    <RPButton
      v-if="program.provider === 'Q8_SMILES'"
      link
      class="py-6"
      href="/profile/loyalty/register"
    >
      {{ $t('loyalty.q8smiles.form.add.button.secondary') }}
    </RPButton>
  </div>
</template>

<script setup lang="ts">
import RPButton from '@/components/RPButton/RPButton.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import LoyaltyIcon from '@/components/LoyaltyIcon.vue';

import { useI18n } from 'vue-i18n';
import { ref, computed } from 'vue';

const { t } = useI18n();

const props = defineProps({
  program: {
    type: Object,
    required: true
  },
  errorAPI: {
    type: String,
    default: ''
  }
});
const emit = defineEmits(['loyaltyFields', 'resetErrors']);

const isQ8 = computed(() => props.program.provider === 'Q8_SMILES');

const cardNumber = ref('');
const errorCard = ref(null);
const errorEmail = ref(null);

const resetError = () => {
  errorCard.value = null;
  errorEmail.value = null;
  emit('resetErrors');
};

// Repsol validation. This is a valid number 7896981514431830014
// Luhn Check as it is from backend
const checksum = (code: string): number => {
  const parity = code.length % 2;
  let sum = 0;

  for (let i = code.length - 1; i >= 0; i--) {
    let digit = Number.parseInt(code.charAt(i));

    if (i % 2 == parity) {
      digit *= 2;
    }

    if (digit > 9) {
      digit -= 9;
    }

    sum += digit;
  }

  return sum % 10;
};

const isLuhnValid = (fullcode: string): boolean => {
  return checksum(fullcode) === 0;
};
const isRepsolLengthAndStartWith = () => {
  return (cardNumber.value).startsWith('7896981') && (cardNumber.value).length === 19;
};
const isRepsolValid = async () => {
  if (!isLuhnValid(cardNumber.value) || !isRepsolLengthAndStartWith()) {
    errorCard.value = 'loyalty.error_add';
    return false;
  } else return true;
};

// Card validation
const isCardValid = async () => {
  errorCard.value = '';

  if (!cardNumber.value) {
    errorCard.value = 'loyalty.error_field_required';
    return false;
  }

  if (props.program.name === 'Repsol Move') {
    return await isRepsolValid();
  }
  if (props.program.provider === 'Q8_SMILES') {
    const isQ8CardValid = cardNumber.value.length >= 13;
    errorCard.value = !isQ8CardValid ? 'loyalty.error_add' : null;
    return isQ8CardValid;

  }
  return true;
};

// Email Validation
const modelEmail = ref('');
const validateEmail = async () => {
  if (!modelEmail.value.length) {
    errorEmail.value = t('errors.required_field_error', { field: 'Email' });
    return false;
  }
  const re = /\S+@\S+\.\S+/;
  const checkEmailFormat = re.test(modelEmail.value);
  if (!checkEmailFormat) {
    errorEmail.value = t('errors.email_not_valid');
    return false;
  }
  return true;
};

const sendSubscriptionNumber = async () => {
  const isValidNumber = await isCardValid();
  const isQ8 = props.program.provider === 'Q8_SMILES';

  const isValidEmail = isQ8 ? await validateEmail() : true;

  if (isValidNumber && isValidEmail) {
    emit('loyaltyFields', cardNumber.value, modelEmail.value);
  }
};
// for trigger the submit on soft native keyboard
window.onKeyBoardConfirm = () => sendSubscriptionNumber();
</script>